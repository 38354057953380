@import url(https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap);
@-webkit-keyframes moveInLeft {
  /* start */
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }
@keyframes moveInLeft {
  /* start */
  0% {
    opacity: 0;
    transform: translateX(-10rem); }
  80% {
    transform: translateX(1rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }

@-webkit-keyframes moveInRight {
  /* start */
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInRight {
  /* start */
  0% {
    opacity: 0;
    transform: translateX(10rem); }
  80% {
    transform: translateX(-1rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }

@-webkit-keyframes moveInBottom {
  /* start */
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }

@keyframes moveInBottom {
  /* start */
  0% {
    opacity: 0;
    transform: translateY(3rem); }
  /* finish */
  100% {
    opacity: 1;
    transform: translate(0); } }

*, *::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: inherit; }

html {
  font-size: 62.5%; }

body {
  font-family: 'Lato', sans-serif;
  font-weight: 400;
  line-height: 1.7;
  color: #999;
  box-sizing: border-box; }

.heading-primary {
  color: #fff;
  -webkit-backface-visibility: hidden;
          backface-visibility: hidden;
  margin-bottom: 2rem;
  text-transform: uppercase;
  font-weight: 700; }
  .heading-primary--main {
    display: block;
    font-size: 6rem;
    letter-spacing: 2rem;
    font-weight: 300;
    margin-bottom: 1rem;
    text-shadow: 2px 2px 2px #2316BC;
    -webkit-animation: moveInLeft 1s ease-out;
            animation: moveInLeft 1s ease-out; }
  .heading-primary--sub {
    display: block;
    font-size: 3rem;
    letter-spacing: .5rem;
    color: #ACA6F1;
    text-shadow: 2px 2px 2px #610CB8;
    -webkit-animation: moveInRight 1s ease-out;
            animation: moveInRight 1s ease-out; }

.heading-secondary {
  font-size: 3.5rem;
  text-transform: uppercase;
  font-weight: 700;
  color: #ACA6F1;
  text-shadow: 2px 2px 2px #2316BC;
  -webkit-animation: moveInBottom 1s ease-out;
          animation: moveInBottom 1s ease-out; }
  .heading-secondary span {
    display: block; }

.contact {
  margin: 1rem 2rem; }
  .contact a {
    text-decoration: none; }
  .contact__info {
    display: block;
    padding: 1rem 2rem;
    font-size: 3rem;
    color: #F39BDF;
    text-shadow: 2px 2px 2px #610CB8;
    text-transform: uppercase;
    margin-bottom: -2rem; }
  .contact__soc-links {
    display: inline-block;
    padding: 1rem 2rem;
    font-size: 3rem;
    color: #fff;
    text-shadow: 2px 2px 2px #2316BC;
    font-family: monospace; }

.u-center-text {
  text-align: center !important; }

.u-mb-small {
  margin-bottom: 1.5rem !important; }

.u-mb-med {
  margin-bottom: 4rem !important; }

.u-mb-big {
  margin-bottom: 8rem !important; }

.u-mt-small {
  margin-bottom: 1.5rem !important; }

.u-mt-med {
  margin-bottom: 4rem !important; }

.u-mt-big {
  margin-bottom: 8rem !important; }

.u-mt-huge {
  margin-bottom: 10rem !important; }

.btn, .btn:link, .btn:visited {
  text-transform: uppercase;
  text-decoration: none;
  padding: 1.5rem 1.4rem;
  display: inline-block;
  border-radius: 10rem;
  transition: all .2s;
  position: relative;
  font-size: 1.6rem;
  background-image: linear-gradient(to bottom right, rgba(243, 155, 223, 0.8), rgba(195, 0, 152, 0.8));
  background-size: cover;
  background-position: top;
  color: #fff;
  border: none;
  cursor: pointer; }

.btn:hover {
  transform: translateY(-3px);
  box-shadow: 0 1rem 2rem rgba(0, 0, 0, 0.2); }
  .btn:hover::after {
    transform: scaleX(1.4) scaleY(1.6);
    opacity: 0; }

.btn:active, .btn:focus {
  outline: none;
  transform: translateY(-1px);
  box-shadow: 0 0.5rem 1rem rgba(0, 0, 0, 0.2); }

.btn:after {
  content: "";
  display: inline-block;
  height: 100%;
  width: 100%;
  border-radius: 10rem;
  position: absolute;
  top: 0;
  left: 0;
  z-index: -1;
  transition: all .4s; }

.loading-container.done {
  opacity: 0;
  visibility: hidden; }

.row {
  max-width: 114rem;
  margin: 0 auto; }
  .row:not(:last-child) {
    margin-bottom: 8rem; }
  .row::after {
    content: "";
    display: table;
    clear: both; }
  .row [class^="col-"] {
    float: left; }
    .row [class^="col-"]:not(:last-child) {
      margin-right: 6rem; }
  .row .col-1-of-2 {
    width: calc((100% - 6rem) / 2); }
  .row .col-1-of-3 {
    width: calc((100% - 2 * 6rem) / 3); }
  .row .col-2-of-3 {
    width: calc(2 * ((100% - 2 * 6rem) / 3) + 6rem); }
  .row .col-1-of-4 {
    width: calc((100% - 3 * 6rem) / 4); }
  .row .col-2-of-4 {
    width: calc(2 * ((100% - 3 * 6rem) / 4) + 6rem); }
  .row .col-3-of-4 {
    width: calc(3 * ((100% - 3 * 6rem) / 4) + (2 * 6rem)); }

.home {
  height: 100vh;
  width: 100%;
  background-image: linear-gradient(to bottom right, rgba(169, 112, 227, 0.8), rgba(97, 12, 184, 0.8)), url(/static/media/honeycomb.b5014bcf.webp);
  background-size: cover;
  background-position: top;
  position: relative; }
  .home__heading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center; }
  .home .heading-secondary {
    margin-bottom: 2rem; }

